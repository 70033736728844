<template>
<!-- <div class="grid place-items-center h-[calc(85vh)]">
    <div class="py-12">
        <div class="my-4 sm:max-w-6xl w-full bg-gray-100 dark:bg-gray-800">
            <div class="lg:flex justify-between">
                <div class="sm:pl-5 sm:py-5 p-4">
                    <h1 class="text-xl font-semibold text-gray-700 dark:text-gray-200">Welcome to the {{ mainStore.user_exam.title }}!</h1>

                    <div class="flex items-center py-5">
                        <img src="https://cdn.tuk.dev/assets/templates/classified/12.png" class="w-12 h-12 object-cover object-centre rounded-full" alt="user avatar" />
                        <div class="ml-4">
                            <h2 class="text-sm text-gray-700 dark:text-gray-200">{{ mainStore.user.name }}</h2>
                            <p class="text-xs text-gray-600 dark:text-gray-200">{{ mainStore.user.email }}</p>
                        </div>
                    </div>

                    <div class="flex justify-start items-center lg:mt-0 mt-4">
                        <button @click="startExam(this.$route.params.id)" class="hover:opacity-90 py-2  sm:px-6 px-3 bg-black text-white text-sm sm:mr-4 mr-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">Start Exam</button>
                    </div>
                </div>
                <div class="lg:w-60 w-full lg:mt-0 mt-4">
                    <img src="https://cdn.tuk.dev/assets/templates/classified/map.png" class="h-full w-full" alt="map" />
                </div>
            </div>
        </div>
        <div class="my-4 max-w-6xl  bg-gray-100 dark:bg-gray-800 p-5">
            <h1 class="text-xl font-semibold text-gray-700 dark:text-gray-200">Exam Details</h1>

            <div class="lg:flex justify-between items-center mt-5">
                <div class="lg:w-1/3">
                    <div>
                        <div class="flex text-sm">
                            <h2 class="text-gray-700 dark:text-gray-200 mr-2">Number of Attempts:</h2>

                            <p class="dark:text-white">2</p>
                        </div>
                        <div>
                            <div class="flex text-sm my-4">
                                <h2 class="text-gray-700 dark:text-gray-200 mr-2">Close Date:</h2>

                                <p class="dark:text-white">November 31st, 2023</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lg:w-1/3">
                    <div>
                        <div class="flex text-sm">
                            <h2 class="text-gray-700 dark:text-gray-200 mr-2">Author:</h2>

                            <p class="dark:text-white">OHSAA</p>
                        </div>
                        <div>
                            <div class="flex text-sm my-4">
                                <h2 class="text-gray-700 dark:text-gray-200 mr-2">Passing Score:</h2>

                                <p class="dark:text-white">75%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lg:w-1/3">
                    <div>
                        <div class="flex text-sm">
                            <h2 class="text-gray-700 dark:text-gray-200 mr-2">Owner:</h2>

                            <p class="dark:text-white">First owner</p>
                        </div>
                        <div>
                            <div class="flex text-sm my-4">
                                <h2 class="text-gray-700 dark:text-gray-200 mr-2">Used For:</h2>

                                <p class="dark:text-white">8 months</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-4 max-w-6xl bg-gray-100 dark:bg-gray-800 p-5">
            <h1 class="text-xl font-semibold text-gray-700 dark:text-gray-200">Exam Instructions</h1>
            <p class="text-sm leading-snug text-gray-600 dark:text-gray-200 py-3 lg:pr-32">
                Welcome to our online testing platform! Please read these instructions carefully before proceeding to the test.

                This is a pass or fail exam. To successfully pass the test, you will need to achieve a score of 75% or higher. Anything less will result in a fail.
                <br><br>
                <b>1. Test Attempts:</b> You will have two attempts to take the exam. Make sure to prepare thoroughly before each attempt to increase your chances of success. The highest score of your two attempts will be taken as your final score.
                <br><br>
                <b>2. Test Format:</b> The questions will be in a mix of formats such as multiple-choice, true or false, and fill-in-the-blank. Make sure to read each question and the corresponding instructions carefully.
                <br><br>
                <b>3. Passing Score:</b> You will need to achieve a score of 75% or higher to pass the exam. If your score is less than 75%, you will be able to retake the test for a second attempt.
                <br><br>
                <b>4. Retaking the Test:</b> If you fail on your first attempt, you'll have one more chance to take the test again. Be sure to review your mistakes and understand the areas you need to improve on before your second attempt.
                <br><br>
                <b>5. Results:</b> Your results will be made available immediately after completing the test. If you're taking the test for a second time, only the highest of your two scores will be recorded.
                <br><br>

                Remember, the aim of this exam is to assess your understanding of the subject matter. Ensure to prepare thoroughly and give it your best shot. Good luck!
            </p>
        </div>
    </div>
</div> -->

<div class="">
    <div class="mx-auto bg-white h-full">
        <div class="relative">
            <img src="https://host.refreps.com/images/taso_v2.png" class="lg:block md:hidden hidden object-fit h-[100vh]" />
            <img src="https://host.refreps.com/images/taso_v2.png" class="lg:hidden md:block hidden object-fit h-[100vh]" />
            <img src="https://host.refreps.com/images/taso_v2.png" class="lg:hidden md:hidden block object-fit h-[100vh]" />

            <div class="absolute top-0 backdrop_hero_XXXI lg:px-24 md:px-12 px-2 xl:py-28 lg:py-14 md:py-10 py-10 lg:h-full w-full">

                <div class="sm:flex mb-4">
                    <div class="mb-1 flex-shrink-0 sm:mb-0 sm:mr-4">
                        <img :src="mainStore.user.profileImage" alt="" class="w-16 h-16 rounded-full object-cover">
                    </div>
                    <div class="">
                        <h4 class="text-2xl font-bold">{{ mainStore.user.name }}</h4>
                        <p class="">{{ mainStore.user.email }}</p>
                    </div>
                </div>

                <p class="lg:text-3xl md:text-3xl text-3xl font-bold text-gray-800 lg:max-w-[515px] w-full">
                    {{ mainStore.user_exam.currentYear }} {{ mainStore.user_exam.title }}
                </p>
                <p class="text-lg text-gray-600 lg:w-[715px] w-full pt-8">
                    This is a pass or fail exam. To successfully pass, you will need to achieve a score of {{ mainStore.user_exam.scoreToPass }}% or higher. Any score lower than {{ mainStore.user_exam.scoreToPass }}% will result in a fail. If you fail this exam on the first attempt, you will be given one additional attempt to achieve a score of {{ mainStore.user_exam.scoreToPass }}% or higher. If you do not pass this exam on the second attempt, you must contact your instructor or state licensing organization for further assistance.
                </p>
                <div @click="startExam(this.$route.params.id)" class="flex items-center justify-between bg-gray-800 px-4 py-3 lg:w-[211px] w-full mt-8 hover:bg-gray-600 cursor-pointer duration-200">
                    <button class="text-white font-medium">
                        Start Exam
                    </button>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.375 3.5L12.875 8L8.375 12.5" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12.25 8H3.125" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>

                <div class="grid grid-cols-2 gap-6 mt-8 max-w-lg">

                    <div class="flex items-center gap-3">
                    <font-awesome-icon icon="fa-solid fa-badge-check" class="w-6 h-6" />
                    <p class="text-lg text-gray-600">
                        Passing Score:
                        <b><span class="hover:border-b border-[#4B5563] text-xl cursor-pointer">{{ mainStore.user_exam.scoreToPass }}%</span></b>
                    </p>
                </div>
                <div class="flex gap-3">
                    <font-awesome-icon icon="fa-solid fa-lock" class="w-6 h-6" />
                    <p class="text-lg leading-none text-gray-600">
                        Attempts Remaining:
                        <b><span class="hover:border-b border-[#4B5563] text-xl cursor-pointer">{{ mainStore.user_exam.maxAttempts }}</span></b>
                    </p>
                </div>

                <div class="flex items-center gap-3">
                    <font-awesome-icon icon="fa-solid fa-list" class="w-6 h-6" />
                    <p class="text-lg text-gray-600">
                        Total Questions:
                        <b><span class="hover:border-b border-[#4B5563] text-xl cursor-pointer">{{ mainStore.questions.length }}</span></b>
                    </p>
                </div>

                <!-- <div v-if="mainStore.user_exam.state == 'TX'" class="flex items-center gap-3">
                    <font-awesome-icon icon="fa-solid fa-list" class="w-6 h-6" />
                    <p class="text-lg text-gray-600">
                        Total Questions:
                        <b><span class="hover:border-b border-[#4B5563] text-xl cursor-pointer">{{ mainStore.user_exam.questions.length }}</span></b>
                    </p>
                </div> -->
                <div class="flex items-center gap-3">
                    <font-awesome-icon icon="fa-solid fa-clock" class="w-6 h-6" />
                    <p class="text-lg text-gray-600">
                        Time Limit:
                        <b><span class="hover:border-b border-[#4B5563] text-xl cursor-pointer">None</span></b>
                    </p>
                </div>
                <div class="flex items-center gap-3">
                    <font-awesome-icon icon="fa-solid fa-calendar-days" class="w-6 h-6" />
                    <p class="text-lg text-gray-600">
                        Closed Date:
                        <b><span class="hover:border-b border-[#4B5563] text-xl cursor-pointer">None</span></b>
                    </p>
                </div>

                </div>


                
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapStores,
    mapActions
} from "pinia";
import useMainStore from "@/stores/main";

export default {
    name: "PreTestInstructions",
    components: {

    },
    data() {
        return {

        }
    },
    computed: {
        ...mapStores(useMainStore),
    },
    mounted() {

    },
    methods: {
        ...mapActions(useMainStore, ['startExam']),
    }
}
</script>

<style>
.backdrop_hero_XXXI {
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(20px);
}
</style>
