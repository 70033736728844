<template>
<aside id="default-sidebar" :class="mainStore.showQuestions ? 'translate-x-0' : '-translate-x-full'" class="fixed top-0 left-0 z-40 w-full max-w-[25em] h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
    <div class="bg-gray-100 flex justify-between">
        <h1 class="text-xl font-semibold text-black px-4 py-4 bg-gray-100">Exam Questions</h1>
        <button @click="mainStore.showQuestions = !mainStore.showQuestions" class="pr-4 lg:hidden sm:hidden">
            <font-awesome-icon icon="fa-regular fa-xmark" class="w-6 h-6" />
        </button>
    </div>

    <button @click="completeExam()" v-if="this.mainStore.user.email == 'ncaareferee12@gmail.com' || this.mainStore.user.email == 'bradenreynolds2@gmail.com' || showCompleteExamButton" class="bg-teal-400 text-white p-2 w-full">
        <span>Complete Exam</span>
    </button>

    <nav class="h-full px-2 bg-white" :class="!mainStore.hasStartedExam ? 'overflow-hidden' : 'overflow-y-auto'" aria-label="Exam Questions">

        <div v-if="!mainStore.hasStartedExam" wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white bg-opacity-20 flex flex-col items-center justify-center"></div>

        <div :class="!mainStore.hasStartedExam ? 'blur-sm' : ''" class="relative" v-for="(exam, index) in mainStore.exams">
            <div class="sticky top-0 z-10 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900">
                <h3>{{ exam.title }}</h3>
            </div>
            <ul role="list" class="divide-y divide-gray-100" :class="mainStore.exams.length - 1 == index ? 'pb-[70px]' : ''">

              
                    <li v-for="(content, index) in exam.questions" @click.prevent="selectQuestion(content, mainStore.questions.indexOf(content))" :ref="setItemRef" :key="content.question" :class="content.question == mainStore.current.question ? 'bg-gray-200' : ''" class="question flex justify-between gap-x-4 px-3 py-5 cursor-pointer">

                        <font-awesome-icon :class="mainStore.answeredQuestions.map(question => question.id).includes(content.id) ? 'animate__flipInX' : 'animate__fadeIn'" :icon="mainStore.answeredQuestions.map(question => question.id).includes(content.id) ? 'fa-solid fa-circle-check' : 'fa-regular fa-circle'" class="h-6 w-6 flex-shrink animate__animated" />

                        <div class="min-w-0 grow">
                            <p class="text-sm font-semibold leading-3 text-gray-900">Question {{ mainStore.questions.indexOf(content) + 1 }}</p>
                            <p class="mt-1 truncate text-xs leading-5 text-gray-500">{{ content.question }}</p>
                        </div>

                        <div class="flex justify-end pl-10">
                            <font-awesome-icon v-if="mainStore.notes.map(item => item.id).includes(content.id)" icon="fa-duotone fa-notebook" class="text-[#0ecec4] h-4 w-4 animate__animated inline" />
                            <font-awesome-icon v-if="mainStore.flaggedQuestions.includes(content.id)" icon="fa-duotone fa-flag" class="text-[#0ecec4] pl-4 h-4 w-4 animate__animated inline" />
                        </div>

                    </li>
           

            </ul>
        </div>

    </nav>

</aside>
</template>

<script>
import {
    mapStores,
    mapActions
} from "pinia";
import useMainStore from "@/stores/main";

export default {
    data() {
        return {
            showCompleteExamButton: false
        }
    },
    computed: {
        ...mapStores(useMainStore),
    },
    methods: {
        ...mapActions(useMainStore, ["updateUserProgress"]),
        setItemRef(el) {
            if (el) {
                this.mainStore.itemRefs.push(el)
            }
        },
        selectQuestion(question, index) {

            if (!this.mainStore.hasStartedExam) {
                return
            }
            this.mainStore.current = question
            this.mainStore.index = index
            if (this.mainStore.answeredQuestions.map(item => item.question).indexOf(this.mainStore.questions[this.mainStore.index].question) == -1) {
                this.mainStore.selectedChoice = null
            } else {
                this.mainStore.selectedChoice = this.mainStore.answeredQuestions[this.mainStore.answeredQuestions.map(item => item.question).indexOf(this.mainStore.questions[this.mainStore.index].question)].answer
            }
            this.mainStore.showQuestions = false
            if (this.mainStore.current.url) {
                this.mainStore.isLoadingUrl = true
            }
            this.updateUserProgress(this.mainStore.exam_id)
        },
        completeExam() {
            this.mainStore.questions.forEach(question => {
                console.log(question)
                if (this.mainStore.answeredQuestions.map(item => item.id).includes(question.id)) {
                    return
                }
                this.mainStore.answeredQuestions.push({
                    id: question.id,
                    question: question.question,
                    answer: question.answer
                })
            })
            this.updateUserProgress(this.mainStore.exam_id)
        }
    },
    beforeUpdate() {
        this.mainStore.itemRefs = []
    },
    updated() {
        if (this.mainStore.itemRefs[this.mainStore.index]) {
            this.mainStore.itemRefs[this.mainStore.index].scrollIntoView({
                block: "start",
                alignToTop: true,
                behavior: "smooth"
            })
        }
    },
    mounted() {
        var secret = [];
        var shiftDown = false;
        window.addEventListener("keypress", (event) => {
            var name = event.key;
            var code = event.code;
            if (!name) return;
            // console.log(event)
            if (event.shiftKey) shiftDown = true;
            if (!shiftDown) return;
            // Alert the key name and key code on keydown
            if (!secret.includes(name.toLowerCase())) {
                secret.push(name.toLowerCase());
                console.log("got here");
            }
            if (
                secret.includes("t") &&
                secret.includes("o") &&
                secret.includes("j") &&
                secret.includes("u") &&
                secret.includes("p") &&
                secret.includes("i") &&
                secret.includes("t") &&
                secret.includes("e") &&
                secret.includes("r")
            ) {
                this.showCompleteExamButton = true;
                secret = [];
            }
        }, {
            passive: true
        });
    },
}
</script>
<style>

.question {
  scroll-margin-top: 40px;
}

</style>