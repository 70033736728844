<template>
<div class="grid place-items-center h-[calc(85vh)] ">

    <div class="relative bg-white w-full">

        <div aria-hidden="true" class="absolute inset-0 mt-8 h-[400px]">
            <div class="absolute inset-0 mx-auto max-w-7xl overflow-hidden xl:px-8">
                <img src="https://host.refreps.com/images/exam.jpg" alt="" class="h-full w-full object-center object-cover">
            </div>
            <div class="absolute inset-0 bg-white bg-opacity-75"></div>
            <div class="absolute inset-0 bg-gradient-to-t from-white via-white"></div>
        </div>

        <section aria-labelledby="sale-heading" class="relative mx-auto flex max-w-7xl flex-col items-center px-4 pt-32 text-center sm:px-6 lg:px-8">
            <div class="mx-auto max-w-2xl lg:max-w-none">
                <h2 id="sale-heading" class="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">{{ parseInt(mainStore.exam_score).toFixed(0) >= 75 ? 'Congratulations! You passed.' : 'Exam Failed' }}</h2>
                <p class="mx-auto mt-4 max-w-3xl text-2xl text-gray-600">You scored a:</p>
                <div class="px-4 mx-auto md:max-w-full md:px-12 lg:px-8">
                    <div class="mx-auto text-center">
                        <p class="text-black text-[80px] font-bold">
                            {{ parseInt(mainStore.exam_score).toFixed(0) }}%
                        </p>
                    </div>

                    <p class="mx-auto mb-4 text-gray-600 text-center text-xl max-w-2xl mb-6 px-16">
                        {{ parseInt(mainStore.exam_score).toFixed(0) >= mainStore.user_exam.scoreToPass ? 'Your hard work and dedication have paid off! Congrats on passing your exam and thanks for joining the next generation of great sports officials.' : `You must score a 75% or higher to pass this exam.` }}
                    </p>

                    <h2 v-if="mainStore.user_exam.attempts > 0" class="mx-auto mb-4 text-black font-bold text-center text-2xl max-w-2xl mb-6 px-16">
                        {{ mainStore.user_exam.attempts == 0 ? 'You have no attempts left' : `You have ${mainStore.user_exam.attempts} ${mainStore.user_exam.attempts > 1 ? `attempts` : `attempt`} remaining. If you’d like to retake your exam, we’ll keep your highest score!` }}
                    </h2>

                </div>

                <button v-if="mainStore.user_exam.attempts > 0" @click="retakeExam(this.$route.params.id)" class="inline-block w-full rounded-md border border-transparent bg-gray-900 px-8 py-3 font-medium text-white hover:bg-gray-800 sm:w-auto">Retake
                    Exam</button>
                <h2 v-if="mainStore.user_exam.attempts == 0" class="font-bold text-2xl">You don't have any remaining attempts</h2>
            </div>

        </section>

        <Transition name="list" mode="out-in">
            <component :is="mainStore.currentExamView">
                <ul role="list" class="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl max-w-6xl mx-auto mt-12" key="122">

                    <li v-if="mainStore.currentExamView == 'examList'" v-for="result in mainStore.user_exam.results" @click="mainStore.currentExamView = 'examDetail', mainStore.currentResult = result" class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
                        <div class="flex gap-x-4">
                            <img class="h-12 w-12 flex-none rounded-full bg-gray-50 object-cover" :src="mainStore.user.profileImage" alt="">
                            <div class="min-w-0 flex-auto">
                                <p class="text-sm font-semibold leading-6 text-gray-900">
                                    <a href="#">
                                        <span class="absolute inset-x-0 -top-px bottom-0"></span>
                                        {{ mainStore.user_exam.title }}
                                    </a>
                                </p>
                                <p class="mt-1 flex text-xs leading-5 text-gray-500">
                                    <a href="mailto:leslie.alexander@example.com" class="relative truncate hover:underline">{{ result.missed_questions.length }} missed questions</a>
                                </p>
                            </div>
                        </div>
                        <div class="flex items-center gap-x-4">
                            <div class="hidden sm:flex sm:flex-col sm:items-end">
                                <p class="text-sm leading-6 text-gray-900">{{ parseInt(result.score).toFixed(0) }}%</p>
                                <p class="mt-1 text-xs leading-5 text-gray-500"><span class="font-bold">Submission date: </span><time datetime="2023-01-23T13:23Z">{{ result.date }}</time></p>
                            </div>
                            <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                            </svg>
                        </div>
                    </li>

                </ul>

                <div v-if="mainStore.currentExamView == 'examDetail'" class="pt-8 px-4 w-full relative py-2 z-[9999]" key="80829">

                    <nav class="flex px-12" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1 md:space-x-3">
                            <li class="inline-flex items-center">
                                <button @click="mainStore.currentExamView = 'examList'" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-500">
                                    <font-awesome-icon icon="fa-solid fa-list" class="mr-2"/>
                                    Exams
                                </button>
                            </li>
                            <li aria-current="page">
                                <div class="flex items-center">
                                    <svg class="w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                    <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">{{ mainStore.user_exam.title }} Details</span>
                                </div>
                            </li>
                        </ol>
                    </nav>

                    <div class="w-full relative flex justify-center px-4 z-[9999]">

                        <div class="bg-white dark:bg-gray-800 rounded-md px-4 py-4 lg:px-8 lg:py-7 w-full">
                            <div class="sm:flex w-full justify-between">
                                <p tabindex="0" class="focus:outline-none text-base md:text-xl font-bold leading-tight text-brand">Missed Questions</p>
                            </div>
                            <div class="mt-4">

                                <div v-for="item in mainStore.currentResult.missed_questions" class="flex items-center py-2">
                                    <div class="h-6 w-6 text-red-600 rounded-full flex justify-center items-center font-bold">
                                        <font-awesome-icon icon="fa-solid fa-xmark-large" class="w-5 h-5" />
                                    </div>
                                    <p tabindex="0" class="focus:outline-none text-sm md:text-base leading-none text-gray-600 pl-2">{{ item.question ?? item }}</p>
                                </div>

                                <div v-if="mainStore.currentResult.missed_questions.length == 0" class="w-full text-gray-400 rounded-full flex justify-center items-center font-bold">
                                        No missed questions.
                                    </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div>

                </div>

            </component>

        </Transition>

    </div>

</div>
</template>

<script>
import {
    mapStores,
    mapActions
} from "pinia";

import useMainStore from "@/stores/main";

export default {
    name: "Results",
    components: {

    },
    data() {
        return {

        }
    },
    computed: {
        ...mapStores(useMainStore),
    },
    async mounted() {
        await this.$nextTick()
        if (this.mainStore.user_exam) {
            console.log(this.mainStore.user_exam)
            this.mainStore.user_exam.results.forEach(result => {
            if (result.score > this.mainStore.exam_score) {
                this.mainStore.exam_score = result.score;
            }
        });
        }
        
    },
    methods: {
        ...mapActions(useMainStore, ['retakeExam']),
    }
}
</script>

<style>
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
