import { defineStore } from 'pinia'
import axios from 'axios'
import {
  io
} from "socket.io-client";
import * as Sentry from "@sentry/vue";

export default defineStore('main', {
  state: () => {
    return {
      questions: [],
      exams: [],
      current: {},
      index: 0,
      selectedIndex: 0,
      selectedChoice: '',
      user: {},
      isLoggedIn: false,
      currentView: 'empty',
      answeredQuestions: [],
      showMessageModal: false,
      hasStartedExam: true,
      displayTitle: '',
      displayMessage: '',
      displayIsSendingExam: false,
      displayCancelButton: false,
      displaySubmitButton: false,
      displayReturnButton: false,
      displayReviewButton: false,
      exam_id: '',
      exam_score: 0,
      attempts: 0,
      missed_questions: [],
      user_exam: {},
      ohsaaQuestions: [],
      isInDeveloperMode: true,
      flaggedQuestions: [],
      notes: [],
      showNotes: false,
      currentNote: '',
      theCurrentNote: '',
      showQuestions: false,
      itemRefs: [],
      advanceQuestion: false,
      currentExamView: 'examList',
      currentResult: {},
      percent_complete: 0,
      socket: null,
      examQuestions: [],
      score: 0,
      isLoadingUrl: false
    }
  },
  actions: {
    async getExam(exam_id) {
      var result = false
      this.currentView = 'empty'

      this.isLoadingExam = true
      this.isInDeveloperMode = import.meta.env.VITE_MODE == 'development'
      console.log(import.meta.env.VITE_MODE)

      await axios
        .post(`${import.meta.env.VITE_BASE_LINK}/get-exam`, {
          exam_id: exam_id
        })
        .then((response) => {
          if (response.data.code != 200) {
            result = false
          }
          
          this.questions = response.data.exams.flatMap((exam) => exam.questions)
          this.exams = response.data.exams
          this.user = response.data.user
          this.answeredQuestions = response.data.user_exam.completed_questions
          this.exam_id = response.data.user_exam.exam_id
          this.user_exam = response.data.user_exam
          this.attempts = response.data.user_exam.attempts
          this.questions.length != 0 ? (this.current = this.questions[this.index]) : {}
          this.hasStartedExam = response.data.user_exam.status == 'started'

          switch (response.data.user_exam.status) {
            case 'not_started':
              this.currentView = 'instructions'
              this.notes = []
              this.flaggedQuestions = []
              this.index = 0
              this.current = this.questions[this.index]
              break
            case 'started':
              this.currentView = 'exam'
              this.notes = response.data.user_exam.notes
              this.flaggedQuestions = response.data.user_exam.flagged_questions
              ;(this.index = response.data.user_exam.currentIndex),
                (this.advanceQuestion = response.data.user_exam.advanceQuestion)
              break
            case 'completed':
              this.currentView = 'results'
              this.exam_score =
                response.data.user_exam.results[response.data.user_exam.results.length - 1].score
              this.missed_questions =
                response.data.user_exam.results[
                  response.data.user_exam.results.length - 1
                ].missed_questions
              break

            default:
              this.currentView = 'empty'
              break
          }

          setTimeout(() => {
            this.isLoadingExam = false
          }, 1200)
          result = true
        })
        .catch((error) => {
          console.log(error)
          this.isLoadingExam = false
          result = false
        })

      return result
    },
    async updateUserProgress(id) {
      console.log(
        this.compareArrays(
          this.questions.map((question) => question.id),
          this.answeredQuestions.map((question) => question.id)
        ).toFixed(2)
      )

      await axios
        .post(`${import.meta.env.VITE_BASE_LINK}/update-exam-progress`, {
          exam_id: id,
          completed_questions: this.answeredQuestions,
          flagged_questions: this.flaggedQuestions,
          notes: this.notes,
          currentIndex: this.index,
          advanceQuestion: this.advanceQuestion,
          percentComplete: this.compareArrays(
            this.questions.map((question) => question.id),
            this.answeredQuestions.map((question) => question.id)
          ).toFixed(2)
        })
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async showDisplayModal() {
      this.percent_complete = this.compareArrays(
        this.questions.map((question) => question.id),
        this.answeredQuestions.map((question) => question.id)
      )
      console.log(this.percent_complete)

      if (this.percent_complete < 100) {
        this.displayTitle = 'Exam Not Complete'
        this.displayMessage =
          'You have not answered all the questions. Please answer all the questions before submitting.'
        this.displayCancelButton = false
        this.displaySubmitButton = false
        this.displayReturnButton = true
        this.showMessageModal = true
        return
      }

      this.displayTitle = 'Are You Sure You Want To Submit?'
      this.displayMessage =
        this.flaggedQuestions.length == 0
          ? 'Once you have submitted your exam, you will not be able to return.'
          : `You have ${
              this.flaggedQuestions.length > 1
                ? `${this.flaggedQuestions.length} questions`
                : `${this.flaggedQuestions.length} question`
            } flagged for review. You may still submit your exam. However, once you have done so, you will not be able to edit any of your responses, and your score will be final.`
      this.displayCancelButton = true
      this.displaySubmitButton = true
      this.displayReturnButton = false
      this.displayIsSendingExam = false
      this.displayReviewButton = this.flaggedQuestions.length != 0
      this.showMessageModal = true
    },
    async submitExam(exam_id) {
      this.displayTitle = 'Submitting Exam'
      this.displayMessage = 'Please wait while we submit your exam.'
      this.displayIsSendingExam = true
      this.displayCancelButton = false
      this.displaySubmitButton = false
      this.displayReturnButton = false

      

      var correct = 0

      this.answeredQuestions.forEach((userQuestion) => {
        var question = this.questions.find((q) => q.id == userQuestion.id)
        if (userQuestion.answer == question.answer) {
          correct++
        }else{
          this.missed_questions.push(question)
        }

      })

       this.score = ((correct / this.questions.length) * 100).toFixed(0)

      await axios
        .post(`${import.meta.env.VITE_BASE_LINK}/submit-exam`, {
          exam_id: exam_id,
          ids: this.exams.map((exam) => exam.id),
          completed_questions: this.answeredQuestions,
          org_ids: this.user.organizations.map((org) => org.companyID),
          user_id: this.user.id,
          score: this.score,
          missed_questions: this.missed_questions,
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.currentView = 'results'
            this.showMessageModal = false
            this.displayIsSendingExam = false
            this.displayCancelButton = false
            this.displaySubmitButton = false
            this.displayReturnButton = false
            this.exam_score = this.score
            this.hasStartedExam = false
            this.answeredQuestions = []
            this.user_exam = response.data.updatedExam
            this.flaggedQuestions = []
          } else {
            this.showMessageModal = false
            this.displayIsSendingExam = false
            this.displayCancelButton = false
            this.displaySubmitButton = false
            this.displayReturnButton = false
            this.hasStartedExam = false
            this.exam_score = this.score
            Sentry.captureException(response.data.message);
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.log(error)
        })
    },
    async retakeExam(exam_id) {
      await axios
        .post(`${import.meta.env.VITE_BASE_LINK}/retake-exam`, {
          exam_id: exam_id
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.currentView = 'exam'
            this.showMessageModal = false
            this.displayIsSendingExam = false
            this.displayCancelButton = false
            this.displaySubmitButton = false
            this.displayReturnButton = false
            this.hasStartedExam = true
            this.answeredQuestions = []
            this.user_exam = response.data.user_exam
            this.index = 0
            this.selectedChoice = ''
            this.answeredQuestions = []
            this.current = this.questions[this.index]
            window.location.reload()
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async startExam(exam_id) {
      await axios
        .post(`${import.meta.env.VITE_BASE_LINK}/start-exam`, {
          exam_id: exam_id
        })
        .then((response) => {
          this.currentView = 'exam'
          this.hasStartedExam = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    loadAndOpenNote() {
      if (
        this.notes.map((item) => item.question).indexOf(this.questions[this.index].question) == -1
      ) {
        this.currentNote = ''
      } else {
        this.currentNote =
          this.notes[
            this.notes.map((item) => item.question).indexOf(this.questions[this.index].question)
          ].text
      }
      console.log(this.currentNote)
      this.showNotes = true
    },
    flag() {
      if (this.flaggedQuestions.includes(this.current.id)) {
        this.flaggedQuestions.splice(this.flaggedQuestions.indexOf(this.current.id), 1)
      } else {
        this.flaggedQuestions.push(this.current.id)
      }
      this.updateUserProgress(this.exam_id)
    },
    compareArrays(superset, subset) {
      // Convert arrays to sets for easier comparison
      let supersetSet = new Set(superset)
      let subsetSet = new Set(subset)

      // Calculate intersection of the two sets
      let intersection = new Set([...supersetSet].filter((x) => subsetSet.has(x)))

      // If superset is not empty, calculate and return percentage
      if (supersetSet.size) {
        let percentage = (intersection.size / supersetSet.size) * 100
        return percentage
      } else {
        return 0
      }
    },
    connectToSocket() {
      this.socket = io("wss://notifications.refreps.com", {
          query: {
            id: 'exam'
          },
          withCredentials: true,
      });
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        paths: [
          'flaggedQuestions',
          'currentView',
          'index',
          'current',
          'selectedIndex',
          'selectedChoice',
          'exam_id',
          'notes',
          'advanceQuestion'
        ]
      }
    ]
  }
})
