import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '../views/LandingPageView.vue'
import SetupExamPage from '../views/SetupExamPageView.vue'
import InvalidPage from '../views/InvalidPageView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'start',
      component: LandingPage
    },
    {
      path: '/:id',
      name: 'exam',
      component: SetupExamPage
    },
    {
      path: '/invalid',
      name: 'invalid',
      component: InvalidPage,
      meta: {
        message: 'We couldn\'t find you exam. Make sure you have completed the RefReps course.'
      }
    },
  ],
})

export default router
