<template>
<main>
    <section class="pt-16 pb-44 bg-gray-50 overflow-hidden h-[100vh]">
        <div class="relative container mx-auto px-4">
            <!-- <img class="absolute top-0 left-0" src="gradia-assets/elements/http-codes/radial.svg" alt=""> -->
            <div class="relative z-10">
                <h1 class="mb-16 font-extrabold text-transparent text-[170px] bg-clip-text bg-gradient-to-r from-teal-400 to-blue-600">
                    404
                </h1>
                <div class="md:max-w-xl">
                    <p class="mb-6 font-heading font-semibold text-sm text-gray-500 uppercase tracking-px">RefReps</p>
                    <h2 class="mb-5 font-heading font-bold text-6xl text-gray-900">No Exam Found</h2>
                    <p class="mb-11 text-gray-600 text-3xl">{{ this.$route.meta.message }}</p>
                    <div class="mt-10">
                        <a href="#" class="text-xl font-semibold leading-7 text-teal-400"><span aria-hidden="true">&larr;</span> Back to RefReps OEM</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
</template>

<script>
export default {

}
</script>
