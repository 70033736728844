<template>
<div class="flex items-center justify-end pt-6 pr-3">
    <div class="relative inline-block text-left pr-6" v-click-away="onClickAway">
        <div>
            <button @click="showSettings = !showSettings" type="button" class="flex items-center rounded-full text-black hover:text-gray-600" id="menu-button" aria-expanded="true" aria-haspopup="true">
                <span class="sr-only">Open options</span>
                <font-awesome-icon icon="fa-regular fa-gear" class="w-6 h-6" />
            </button>
        </div>

        <div v-show="showSettings" :class="showSettings ? 'animate__zoomIn' : ''" class="animate__animated w-96 absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-xl" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
            <div class="p-4" role="none">
                <label class="relative inline-flex items-center cursor-pointer">
                    <input @change="mainStore.updateUserProgress(mainStore.exam_id)" v-model="mainStore.advanceQuestion" type="checkbox" value="" class="sr-only peer">
                    <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-black"></div>
                    <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Automatically advance to next question</span>
                </label>

            </div>
        </div>
    </div>
</div>

<div class="grid place-items-center lg:h-[calc(85vh)] xs:h-[calc(100vh)]" v-touch:swipe.left="goToNext" v-touch:swipe.right="goBack">
    <div class="flex justify-between items-center">
        <button @click="goBack" type="button" class="inline-block h-full px-3 py-[20em] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-400 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10" data-te-ripple-init data-te-ripple-color="light">
            <font-awesome-icon icon="fa-regular fa-arrow-left" class="w-6 h-6 mr-2" />
        </button>

        <div class="px-4 py-4 mx-auto max-w-6xl lg:py-20 xs:pb-60">

            <div class="max-w-6xl w-full mb-10 md:mx-auto sm:text-center md:mb-12">

                <div>

                    <p class="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-gray-400 uppercase rounded-full bg-teal-accent-400">
                        Question {{ mainStore.index + 1 }} of {{ mainStore.exams.flatMap(exam => exam.questions).length }}
                    </p>
                    <div class="w-[800px] h-auto mx-auto mb-6">
                        <vue-plyr v-show="mainStore.current.url && !mainStore.isLoadingUrl" ref="plyr" class="bg-gray-50">
                            <video ref="video" id="vid1" :src="mainStore.current.url" controls></video>
                        </vue-plyr>
                        <div v-if="mainStore.isLoadingUrl" class="flex items-center justify-center w-[800px] h-[600px] bg-gray-50 mb-6">
                            <div class="w-10 h-10 border-2 border-t-[4px] border-gray-200 rounded-full animate-spin"></div>
                        </div>
                    </div>
                    <h2 class="max-w-xl mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 md:mx-auto">
                        {{ mainStore.current.question }}
                    </h2>
                </div>

            </div>
            <div class="max-w-lg space-y-3 sm:mx-auto lg:max-w-xl">

                <div v-for="answer in mainStore.current.choices" @click="selectChoice(answer)" :class="mainStore.selectedChoice == answer ? 'bg-gray-50' : ''" class="flex items-center p-4 transition-colors cursor-pointer duration-200 border rounded shadow group hover:bg-gray-50 hover:border-gray-50">
                    <div class="mr-2">
                        <font-awesome-icon :icon="mainStore.selectedChoice == answer ? 'fa-solid fa-circle-check' : 'fa-regular fa-circle'" :class="mainStore.selectedChoice == answer ? 'text-black' : 'text-gray-200'" class="w-6 h-6 mr-2" />
                    </div>
                    <span class="text-gray-800 transition-colors duration-200 group-hover:text-black">{{ answer }}</span>
                </div>
                <!-- <p>Answer: {{ mainStore.current.answer }}</p> -->
                <div class="flex justify-end">

                    <div class="inline-flex rounded-md shadow-sm">
                        <button @click="flag()" :class="mainStore.flaggedQuestions.includes(mainStore.current.id) ? 'text-teal-400' : 'text-gray-700'" v-tooltip="{ content: mainStore.flaggedQuestions.includes(mainStore.current.id) ? 'Unflag Question' : 'Flag Question', html: true }" type="button" class="py-3 px-4 inline-flex hover:bg-gray-100 justify-center items-center gap-2 -ml-px first:rounded-l-lg first:ml-0 last:rounded-r-lg border font-medium bg-white align-middle sm:p-4">
                            <font-awesome-icon icon="fa-duotone fa-flag" class="w-5 h-5" />
                        </button>

                        <button @click="loadAndOpenNote()" :class="mainStore.notes.map(item => item.id).includes(mainStore.current.id) ? 'text-teal-400' : 'text-gray-700'" v-tooltip="{ content: mainStore.notes.map(item => item.id).includes(mainStore.current.id) ? 'Edit Note' : 'Add Note', html: true }" type="button" class="py-3 px-4 inline-flex hover:bg-gray-100 justify-center items-center gap-2 -ml-px first:rounded-l-lg first:ml-0 last:rounded-r-lg border font-medium bg-white align-middle sm:p-4">
                            <font-awesome-icon icon="fa-duotone fa-pen-to-square" class="w-5 h-5" />
                        </button>
                    </div>
                </div>

            </div>

        </div>

        <button @click="goToNext" type="button" class="-ml-0.5 inline-block h-full px-3 py-[20em] text-xs font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-400 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10" data-te-ripple-init data-te-ripple-color="light">
            <font-awesome-icon icon="fa-regular fa-arrow-right" class="w-6 h-6 ml-2" />
        </button>

    </div>
    <div class="pb-32"></div>
</div>

<div class="bg-neutral-50 border-t border-gray-200 text-3xl h-20 text-white text-center fixed inset-x-0 bottom-0 pt-4 px-4">
    <div class="flex justify-between items-center">

        <div></div>
        <button @click="mainStore.showDisplayModal" class="text-[16px] lg:text-[16px] xs:text-[12px] relative inline-flex items-center justify-start py-2 pl-4 pr-4 overflow-hidden font-semibold text-black transition-all duration-150 ease-in-out rounded hover:pr-10 bg-gray-100 group">
            <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-black group-hover:h-full"></span>
            <span class="absolute right-0 pr-4 duration-200 ease-out translate-x-12 group-hover:translate-x-0">
                <svg class="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                </svg>
            </span>

            <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">Submit Exam</span>
        </button>

    </div>

</div>
</template>

<script>
import useMainStore from '@/stores/main'
import {
    mapStores,
    mapActions
} from 'pinia';

import {
    directive
} from "vue3-click-away";

export default {
    name: "Quiz",
    components: {

    },
    directives: {
        ClickAway: directive,
    },
    data() {
        return {
            selectedAnswer: '',
            isProcessing: false,
            isLoading: true,
            showSettings: false,
        }
    },
    computed: {
        ...mapStores(useMainStore),
    },
    mounted() {
        this.$refs.video.addEventListener("loadedmetadata", (event) => {
            this.mainStore.isLoadingUrl = false
        });
    },
    created() {

    },
    methods: {
        ...mapActions(useMainStore, ['showDisplayModal', 'updateUserProgress', 'loadAndOpenNote', 'flag']),
        onClickAway() {
            this.showSettings = false
        },
        selectChoice(choice) {

            this.mainStore.selectedChoice = choice

            if (this.mainStore.answeredQuestions.map(item => item.question).indexOf(this.mainStore.questions[this.mainStore.index].question) == -1) {
                this.mainStore.answeredQuestions.push({
                    id: this.mainStore.current.id,
                    question: this.mainStore.current.question,
                    answer: choice
                })
            } else {
                this.mainStore.answeredQuestions[this.mainStore.answeredQuestions.map(item => item.question).indexOf(this.mainStore.questions[this.mainStore.index].question)].answer = choice
            }

            this.mainStore.current.userAnswer = choice

            this.updateUserProgress(this.$route.params.id)
            if (this.mainStore.advanceQuestion) {
                setTimeout(() => {
                    this.goToNext()
                }, 150);
            }
        },
        goBack() {
            if (this.mainStore.index == 0) return
            this.mainStore.index--
            this.mainStore.current = this.mainStore.questions[this.mainStore.index]
            this.mainStore.selectedChoice = this.mainStore.answeredQuestions.map(item => item.question).indexOf(this.mainStore.questions[this.mainStore.index].question) == -1 ? '' : this.mainStore.answeredQuestions[this.mainStore.answeredQuestions.map(item => item.question).indexOf(this.mainStore.questions[this.mainStore.index].question)].answer
            this.updateUserProgress(this.$route.params.id)
            window.navigator.vibrate(30);
        },
        goToNext() {
            if (this.mainStore.index == this.mainStore.questions.length - 1) return
            this.mainStore.index++
            this.mainStore.current = this.mainStore.questions[this.mainStore.index]

            this.mainStore.selectedChoice = this.mainStore.answeredQuestions.map(item => item.question).indexOf(this.mainStore.questions[this.mainStore.index].question) == -1 ? '' : this.mainStore.answeredQuestions[this.mainStore.answeredQuestions.map(item => item.question).indexOf(this.mainStore.questions[this.mainStore.index].question)].answer
            this.updateUserProgress(this.$route.params.id)
            window.navigator.vibrate(30);
        },

    }
}
</script>

<style>
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.2s linear;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(30px);
    opacity: 0;
}

.slide-fade-leave-active {
    transition: all 0.7s linear;
}
</style>
