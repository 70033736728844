<template>
<div v-if="isLoadingExam" wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-50 opacity-75 flex flex-col items-center justify-center">
    <font-awesome-icon icon="fa-solid fa-loader" class="w-10 h-10 mb-3" spin />
    <h2 class="text-center text-black text-xl font-semibold">Setting Up Test...</h2>
    <p class="w-1/3 text-center text-black">This may take a few seconds, please don't close this page.</p>
</div>

<ExamPageView v-if="!isLoadingExam" />
</template>

<script>
import ExamPageView from "./ExamPageView.vue";

import {
    mapStores,
    mapActions
} from "pinia";

import useMainStore from "@/stores/main";

export default {
    data() {
        return {
            isLoadingExam: false,
        };
    },
    computed: {
        ...mapStores(useMainStore),
    },
    async mounted() {
        this.$nextTick(async () => {
            const result = await this.getExam(this.$route.params.id);
            console.log(result)
            if (!result) {
                this.$router.push({
                    name: 'invalid',
                    meta: {
                        message: 'Oops, there was no exam found.'
                    }
                })
            }
            this.connectToSocket();
        });
    },
    methods: {
        ...mapActions(useMainStore, ['getExam', 'connectToSocket']),
    },
    components: {
        ExamPageView
    }
}
</script>

<style>
.loader {
    border-top-color: #3498db;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
