import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import piniaPersist from 'pinia-plugin-persist'
import * as Sentry from "@sentry/vue";
import App from './App.vue'
import router from './router'

import axios from 'axios';
import VueAxios from 'vue-axios';
import '@fortawesome/fontawesome-pro/js/all.js'

import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'

import Vue3TouchEvents from "vue3-touch-events";
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'



const app = createApp(App)
const pinia = createPinia()

pinia.use(piniaPersist)
app.use(pinia)
app.use(FloatingVue)
app.use(Vue3TouchEvents);
app.use(VuePlyr, {
    plyr: {}
  })

  Sentry.init({
    app,
    dsn: "https://552b12817cb8f574b0db162e9bc8d2d8@o1257122.ingest.us.sentry.io/4507867051458560",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

app.use(VueAxios, axios);
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router)

app.mount('#app')
